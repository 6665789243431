
  import { mapGetters } from 'vuex'
  import * as Sentry from '@sentry/browser'

  export default {
    name: `Standard`,
    props: {
      subject: {
        type: String,
        default: ``,
        required: true
      }
    },
    data() {
      return {
        screeningsList: [],
        form: {
          subject: this.subject,
          screening: ``,
          message: ``,
          documents: {}
        }
      }
    },
    fetch() {
      if (!this.isExternal && (this.isCandidate || this.userIsClient)) {
        this.$api.screenings
          .allLight()
          .then((res) => {
            this.screeningsList = [...res]
          })
          .catch((err) => {
            Sentry.captureException(err)
            this.genericError()
          })
      }
    },
    computed: {
      ...mapGetters({
        userIsClient: `permissions/userIsClient`,
        isExternal: `permissions/userIsExternal`,
        isCandidate: `permissions/userIsCandidate`,
        externalToken: `external/getToken`
      }),
      onlyOneScreening() {
        return this.screeningsList.length === 1
      }
    },
    watch: {
      onlyOneScreening(newValue) {
        this.form.screening = !!newValue ? this.screeningsList[0].value : ``
      },
      subject(newValue) {
        this.form.subject = !!newValue ? this.subject : ``
      }
    },
    methods: {
      genericError() {
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: this.$t(`messages.unknown_error`)
        })
      },
      async sendForm() {
        const contactObsIsValide = await this?.$refs?.contactObs?.validate()
        !!contactObsIsValide && this.requestForm()
      },
      requestForm() {
        const contact = !!this.externalToken ? (f) => this.$api.external.contact(f) : (f) => this.$api.contact.send(f)
        contact({ form: { ...this.form } })
          .then((res) => {
            this.successSendForm(res)
          })
          .catch((err) => {
            this.errorForm(err)
          })
      },
      errorForm(err) {
        const errForm = err?.response?.data?.form

        if (err?.response?.status === 422) {
          this.$flashMessage.error({
            title: this.$t(`messages.error`),
            message: err.response.data.message
          })
        } else if (!!errForm) {
          this.setErrors(errForm)
          this.scrollToError()
        } else {
          this.genericError()
        }
      },
      successSendForm(res) {
        this.$flashMessage.info({
          title: this.$t(`messages.message_sent`),
          message: this.$t(res.message)
        })
        if (!!this.externalToken) {
          this.$router.push({ path: `/external`, query: { token: this.externalToken } })
        } else {
          this.$router.push({ path: `/` })
        }
      },
      scrollToError() {
        const el = document.querySelector(`.v-messages.error--text:first-of-type`)
        el.scrollIntoView({ behavior: `smooth`, block: `center` })
      },
      setErrors(errors) {
        if (!!errors) {
          this.$refs.contactObs.setErrors(errors)
        }
      }
    }
  }
