import { render, staticRenderFns } from "./standard.vue?vue&type=template&id=26028360&scoped=true&"
import script from "./standard.vue?vue&type=script&lang=js&"
export * from "./standard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26028360",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AeqPhoneField: require('/home/vsts/work/1/s/components/AeqPhoneField.vue').default,AeqFileField: require('/home/vsts/work/1/s/components/AeqFileField.vue').default})
